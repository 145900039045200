<template>
  <l-map ref="myMap" class="map" @update:zoom="zoomUpdated" @update:center="centerUpdated" :zoom="zoom" :center="center" @ready="onMapIsReady()">
    
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <l-geo-json :geojson="geojson" :options="options"></l-geo-json>
    <l-marker :lat-lng="markerLatLng" >
      <l-tooltip>Huhu, ich bims, der <b>Neumarkt</b><br>50.93598726241151, 6.947651978711989</l-tooltip>
    </l-marker>
    <p class="z-50 sticky">Selected: {{selectedLatLng.lat}} {{selectedLatLng.lng}}</p>
    <slot></slot>
    
  </l-map>
  
</template>

<script>
// import {LMap, LTileLayer, LMarker, LTooltip, LGeoJson, LIcon} from 'vue2-leaflet';
// import { Icon } from 'leaflet'
// import 'leaflet/dist/leaflet.css'

// import osmtogeojson from '@/assets/osmtogeojson.js'
// import osm_geojson from '@/assets/osm_geojson.js'



import axios from 'axios';


/*
delete Icon.Default.prototype._getIconUrl;

Icon.Default.imagePath = '.';
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
*/

export default {
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LGeoJson
  },
  props: {
    msg: String,
    geojson: {
      type: Object,
      default: () => ({
        "type": "FeatureCollection",
        "features": [
        ]
      }),
    },
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom:14,
      center: [50.93598726241151, 6.947651978711989],
      markerLatLng: [50.93598726241151, 6.947651978711989],
      map: undefined,
      featuresCounter: 0,
      selectedLatLng: {
        lng:0,
        lat:0
      },
      options: {
        style: function(feature) {
          if (feature.relation == 20262880) {
            return {
              weight: 3,
              fillColor: '#00FF00',
              color: '#00FF00'
            }
          } else if (feature.relation == 361320) {
            return {
              weight: 3,
              fillColor: '#17387E',
              color: '#17387E'
            }
          } else {
            //console.log(feature)
            return {
              weight: 3,
              fillColor: '#17387E',
              color: '#17387E'
            }
          }

        },
        eachLayer: (layer) => {
          layer.remove();
        },
        onEachFeature: (feature, layer) => {

          

          //console.log(this.featuresCounter)

          /*if(feature.properties.railway == "level_crossing" || feature.properties.railway == "crossing") {
            layer.bindPopup("Ich bin ein Überweg o.ä. [ID:" + feature.properties["@id"]+"]");
            layer.setIcon(L.icon({
              iconUrl: 'https://img.icons8.com/ios/500/switch-tracks.png',
              iconSize: [25, 25],
              iconAnchor: [5, 5]
            }));
          }

          else if(feature.properties.railway == "signal") {
            console.log(feature)

            var content = '';
            for (const [key, value] of Object.entries(feature.properties)) {
              content += `${key}: ${value} <br>`;
            }

            layer.bindPopup("Ich bin ein Signal [ID:" + feature.properties["id"]+"]<br><br>"+content+"");
            layer.setIcon(L.icon({
              iconUrl: 'https://cdn4.vectorstock.com/i/1000x1000/49/68/traffic-light-railway-stock-icon-element-train-vector-28334968.jpg',
              iconSize: [25, 25],
              iconAnchor: [5, 5]
            }));
          }

          else if(feature.properties.railway == "switch") {
            layer.bindPopup("I bims ne Weiche [ID:" + feature.properties["id"]+"]");
            layer.setIcon(L.icon({
              iconUrl: 'https://img.icons8.com/ios/500/switch-tracks.png',
              iconSize: [25, 25],
              iconAnchor: [5, 5]
            }));
          }

          else*/ if(Array.isArray(feature.properties["relations"]) && feature.properties["relations"].length > 0 && feature.properties["relations"][0].role == "stop") { // && feature.properties["@relations"][0].role == "stop"
            // if('role' in feature.properties["relations"][0]) {
              layer.bindPopup("<b>Haltestelle <i>" + feature.properties.tags["name"]+"</i></b><br>[ID:" + feature.properties["id"]+"]<br>VRS-Referenz-ID:"+feature.properties.tags['VRS:ref']+"");
              layer.setIcon(L.icon({
                iconUrl: 'https://www.clipartmax.com/png/full/8-88170_railway-stop-sign-icon-train-station-icon-red.png',
                iconSize: [30, 30],
                iconAnchor: [5, 5]
              }));

              // console.log(feature.properties["relations"][0])
              // console.log(feature.properties)

              layer.on("click", event  => {
                console.log(event)
              });
            // }
            

          }

          else if(feature.properties.tags["public_transport"] == "stop_position") { // && feature.properties["@relations"][0].role == "stop"
            // if('role' in feature.properties["relations"][0]) {
              layer.bindPopup("<b>Haltestelle <i>" + feature.properties.tags["name"]+"</i></b><br>[ID:" + feature.properties["id"]+"]<br>VRS-Referenz-ID:"+feature.properties.tags['VRS:ref']+"");
              layer.setIcon(L.icon({
                iconUrl: 'https://www.clipartmax.com/png/full/8-88170_railway-stop-sign-icon-train-station-icon-red.png',
                iconSize: [30, 30],
                iconAnchor: [5, 5]
              }));

              layer.on("click", event  => {
                console.log(event)
              });
            // }
            

          }

          // else if(feature.properties["id"] == "relation/1320025") { 
          //   console.log(feature)

          //   layer.bindPopup("[ID:" + feature.properties["id"]+"]");
          //   layer.on("click", function (event) {
          //       console.log(event)

          //       console.log(this.geojson.features[0])
          //   });
          // }


          else  if(feature.geometry.type == "LineString") {

            layer.bindPopup("[ID:" + feature.properties["id"]+"]<br><br><b>Neue Langsamfahrstelle in diesem Bereich erstellen?</b><br><button data-id='"+this.featuresCounter+"' :v-on='createNewSpeedlimitWithIndex("+this.featuresCounter+")' class='button button-filled btn-add'>Lf anlegen</button").on("popupopen", () => {
                // $(".btn-add").on("click", e => {
                //   e.preventDefault();
                //   alert(`now delete layer with id ${feature.properties.id}`);
                // });

                let elements = document.getElementsByClassName("btn-add");

                for(let i = 0; i < elements.length; i++) {
                    elements[i].onclick = () => {
                      this.createNewSpeedlimit(feature)
                    }
                }
              }).on("popupclose", () => {
                layer.setStyle({
                  fillColor: '#17387E',
                  color: '#17387E'
                });
              })


            

              // layer.on('click', ".btn-add", event  => {
              //     this.createNewSpeedlimitWithIndex(0)
              // });

              // layer.bindPopup(popupContent)

              layer.on("mouseover", event  => {
                  layer.setStyle({
                    fillColor: '#ff0000',
                    color: '#ff0000'
                  });
                  // this.createNewSpeedlimit(feature)
              });

              layer.on("mouseout", event  => {
                  layer.setStyle({
                    fillColor: '#17387E',
                    color: '#17387E'
                  });
              });
              

              layer.on("click", event  => {

                layer.setStyle({
                  fillColor: '#ff0000',
                  color: '#ff0000'
                });

                  console.log(event)

                  this.selectedLatLng = event.latlng

                  //const index = this.geojson.features.findIndex(f => f.properties["id"] === feature.properties["id"]);

                  //this.geojson.features[index].properties.selected = true

                  //console.log(this.geojson.features[index])
                  
              });

            


          } else {
            //layer.removeMarker()
          }

          this.featuresCounter++;
          
        }
      },
      // geojson: {
      //   "type": "FeatureCollection",
      //   "features": [
      //   ]
      // }
    }
  },
  methods: {
    zoomUpdated (zoom) {
     this.zoom = zoom;
   },
   centerUpdated (center) {
     this.center = center;
   },
    onMapIsReady() {
      //this.map = this.$refs.myMap.mapObject
      this.$refs.myMap.mapObject.invalidateSize()
    },
    updateMap() {
      //this.map.invalidateSize()
    },
    createNewSpeedlimit(e) {
      console.log("createNewSpeedlimit")
      console.log(e)
      alert('Mit jede Menge Maggi (bääh Nestlè) würde für den ausgewählten Bereich [OpenStreetMap-ID:'+e.id+'] auf der Strecke eine neue Lf angelegt werden hätte können würde wäre.'); //'+e.properties["relations"][0].tags.name+'
    },
    createNewSpeedlimitWithIndex(e) {
      console.log("createNewSpeedlimitWithIndex")
      console.log(e)
    }
  },
  mounted() { 

    /*
    axios.get('https://overpass-api.de/api/interpreter?data=[out:json][timeout:25];relation(5209167);relation(r);(way(r)->.a;node(r););out geom;') 
    //[out:json][timeout:10];relation["route"="tram"]["operator"~"KVB"];node(r);out;
    //[out:json][timeout:25];relation(5209167);relation(r);way(r)->.a;.a out geom; //WORK!
    //[out:json][timeout:25];relation(5209167);relation(r);(way(r)->.a;node(r););out geom; //WORK!!!
    //[out:json][timeout:25];(relation(5231291););(._;>;);out;
    .then(result => {
      console.log(result)
      this.geojson = osmtogeojson(result.data, {flatProperties:false})
      console.log(this.geojson)
    }).catch(function (error) {
      console.log("Error overpass-api:", error);
    });
    */
    
  }
};

</script>

<style scoped>
 .map {
   position: relative;
   width: 100%;
   height: 100%;
   overflow: hidden;
   z-index: 1;
 }
</style>