<template>
  <div id="map">
    <TheHeader></TheHeader>
    <SiteNav></SiteNav>
    <div class="">
      <!-- <div class="md:container md:mx-auto"> -->
      <div class="w-full h-96 mb-3">
        <MapTracker></MapTracker>
      </div>

      <div class="my-6 text-center">
        <p class="text-green-400 font-bold text-xl text-center">JUST IN TIME</p>
        <div class="flex items-center justify-center gap-2">
          <p class="font-bold text-lg text-center">Ankunft:</p>
          <div class="flex flex-col">
            <p class="text-lg">ca. {{new Date().getHours()}}:{{new Date().getMinutes()}} Uhr</p>
            <p class="text-lg">Neumarkt</p>
          </div>
        </div>
        <!-- <p class="font-bold text-lg text-center">Ankunft: </p>
        <p class="text-lg">Neumarkt</p> -->
        <p>1001 + 1002</p>

        <p class="text-gray-300 text-center mt-8">
          <small>Alle Angaben ohne Gewähr!</small>
        </p>
      </div>
      
    </div>
  </div>
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import TheHeader from "@/components/TheHeader";

import { mapGetters } from "vuex";

import MapTracker from "@/components/MapTracker";

export default {
  name: "Map",
  components: {
    SiteNav,
    TheHeader,
    MapTracker,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>
